import React from "react"
import { Link, navigate } from "gatsby"
import Login from '../components/Login'

import Layout from "../components/layout"
import NavBar from "../components/navBar/navBar"
import useAuth from "../hooks/useAuth"

const LoginPage = ({ location }) => {
    const { isAuthenticated } = useAuth()
    if (isAuthenticated) {
        navigate('/')
    }
    const { state: routeState } = location
    let redirect = ''
    if (!routeState?.redirect) {
        redirect = '/'
    } else if (routeState.redirect === '/') {
        redirect = '/'
    } else {
        redirect = `/app/${routeState.redirect}`
    }

    return (
        <Layout>
            <NavBar />
            <h1>Login</h1>
            <p>Please use your credentials to login</p>
            <div>
                <Login redirect={redirect} />
            </div>
            <div>
                <Link to="/forgot-password">Forgot your password?</Link>
            </div>
            <div>
                <Link to="/join-us">Not a member yet? Join us</Link>
            </div>

        </Layout>
    )
}

export default LoginPage
