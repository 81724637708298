import React, { useState } from 'react'
import { navigate } from 'gatsby'
import useAuth from '../hooks/useAuth'

const Login = ({ redirect }) => {
  const { state, login } = useAuth()
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    try{
      await login({ identifier, password }) 
      navigate(redirect)
    }catch(e){
      console.log("Error occurred during authentication")
      alert(e.message)
    }
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">
            Email
          </label>
          <input 
            onChange={ e => {
              setIdentifier(e.target.value)
            }}
            value={ identifier }
            id="username" type="text" placeholder="Username" />
        </div>
        <div>
          <label htmlFor="password">
            Password
          </label>
          <input
            onChange={ e => {
              setPassword(e.target.value)
            }}
            value={ password }
            id="password" type="password" placeholder="******************" />
        </div>
        <div>
          <button type="submit">
            Sign In
          </button>
        </div>
      </form>
      { (error.length > 1) && (
        <p>
          { error }
        </p>
      )}
    </div>
  )
}

export default Login
